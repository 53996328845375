
export interface AddressSummary {
    city: string;
    country: string;
    line1: string;
    name: string;
    postalCode: string;
}
export interface CustomerReturnDeliverySummary {
    address: AddressSummary;
    deliveryTimeUtc: Date | undefined;
    noteNumber: string;
    routeNumber: string;
    status: StatusViewModel;
}
export interface DeliveryLineItemSummary {
    deliveredQuantity: number;
    id: number;
    itemDescription: string;
    itemName: string;
    itemNumber: string;
    lineNumber: string;
    lineNumberSuffix: string;
    orderedQuantity: number | undefined;
    status: StatusViewModel;
    unitOfMeasurement: string;
}
export interface DeliveryOnRouteSummary {
    address: AddressSummary;
    customerName: string;
    deliveredTimeUtc: Date | undefined;
    isReturnDelivery: boolean;
    noteNumber: string;
    orderNumber: string;
    routeNumber: string;
    status: StatusViewModel;
}
export interface DeliveryRouteSummary {
    createdTimeUtc: Date;
    deliveryRouteId: number;
    isCompleted: boolean;
    loadedBlueBoxes: string;
    loadedPackagingPalletFrames: string;
    loadedPackagingPallets: string;
    returnBlueBoxes: string;
    returnPackagingCraneTime: string;
    returnPackagingPalletFrames: string;
    returnPackagingPallets: string;
}
export interface DeliverySummary {
    address: AddressSummary;
    deliveryDate: Date;
    deliveryTimeUtc: Date | undefined;
    noteNumber: string;
    routeNumber: string;
    status: StatusViewModel;
}
export interface DistributionCenterErrorSummary extends ErrorSummary {
    owner: string;
}
export interface DistributionCenterReturnErrorSummary extends ReturnErrorSummary {
    owner: string;
}
export interface DistributionCenterSummary {
    city: string;
    id: number;
    latitude: number;
    longitude: number;
    name: string;
    phoneNumber: string;
    postalCode: string;
    streetName: string;
}
export interface ErrorSummary {
    customerName: string;
    deliveryTimeUtc: Date | undefined;
    orderNumber: string;
    ourReference: string;
    owner: string;
    routeNumber: string;
    ssccNumber: string;
    status: StatusViewModel;
    warehouse: string;
    warehouseArea: string;
}
export interface NotificationSettingSummary {
    city: string;
    id: number;
    integrationId: string;
    line1: string;
    name: string;
    notificationSetting: NotificationSettingViewModel;
    postalCode: string;
}
export interface NotificationSettingViewModel {
    email: string;
    endContactTime: string;
    id: number;
    phoneNumber: string;
    shouldReceiveEmailAfterDelivery: boolean;
    shouldReceiveEmailBeforeDelivery: boolean;
    shouldReceiveEmailWhenDelayed: boolean;
    shouldReceiveSmsAfterDelivery: boolean;
    shouldReceiveSmsBeforeDelivery: boolean;
    shouldReceiveSmsWhenDelayed: boolean;
    startContactTime: string;
}
export interface OrderSummary {
    address: AddressSummary;
    belongingDepartment: string;
    currentDeliveryRouteId: number | undefined;
    deliveryTimeUtc: Date | undefined;
    isInTransit: boolean;
    orderNumber: string;
    orderType: OrderType;
    ourReference: string;
    routeNumber: string;
    salesAccountNumber: string;
    status: StatusViewModel;
}
export interface PackageSummary {
    deliveryTimeUtc: Date | undefined;
    lineItemCount: number;
    packageGroupSsccNumber: string;
    packageType: string;
    ssccNumber: string;
    status: StatusViewModel;
    warehouse: string;
    warehouseArea: string;
}
export interface PlannedDeliveryRouteSummary {
    created: Date;
    id: number;
    name: string;
    stopsCount: number;
}
export interface ReturnErrorSummary {
    customerName: string;
    deliveryTimeUtc: Date | undefined;
    noteNumber: string;
    owner: string;
    routeNumber: string;
    ssccNumber: string;
    status: StatusViewModel;
}
export interface ReturnPackageSummary {
    created: Date;
    deliveredTime: Date | undefined;
    noteNumber: string;
    ssccNumber: string;
    status: StatusViewModel;
}
export interface SalesAccountSummary {
    accountNumber: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    id: number;
    name: string;
    phoneNumber1: string;
    phoneNumber2: string;
    postalCode: string;
    status: number;
    storeCode: string;
}
export interface StoreErrorSummary extends ErrorSummary {
    owner: string;
}
export interface StoreReturnErrorSummary extends ReturnErrorSummary {
    owner: string;
}
export enum StatusColor {
    Blue = 0,
    Green = 1,
    Yellow = 2,
    Red = 3
}
export interface StatusViewModel {
    color: StatusColor;
    name: string;
}
export interface EventViewModel {
    description: string;
    id: number;
    identifier: string;
    latitude: number;
    longitude: number;
    number: string;
    timestamp: Date;
    type: DeliveryType;
    userName: string;
}
export interface SalesAccountHeaderInfo extends BaseHeaderInfo {
    header1: string;
    header2: string;
    header3: string;
}
export interface SalesAccountNotificationSettingView {
    addresses: NotificationSettingSummary[];
    name: string;
    notificationSetting: NotificationSettingViewModel;
    salesAccountAddress: AddressSummary;
}
export interface SalesAccountOrderView extends SalesAccountView {
    orders: OrderSummary[];
}
export interface SalesAccountView {
    accountNumber: string;
    line1: string;
    line2: string;
    name: string;
}
export interface BaseHeaderInfo {
    header1: string;
    header2: string;
    header3: string;
}
export interface BasePageView<T> {
    breadcrumb: BreadcrumbPath[];
    content: T;
    headerContactInfo: BaseHeaderInfo;
    id: string;
    statistics: SalesAccountStatistics;
}
export interface BreadcrumbPath {
    id: string;
    type: LogisticsType;
}
export interface DeliveryInfo {
    contactPerson: string;
    deliveredBy: string;
    deliveredTimeUtc: Date | undefined;
    deliveredToName: string;
    deliveredToPostalCodeAndCity: string;
    deliveredToStreetAndNumber: string;
    deliveryDescriptionLink: string;
    deliveryDescriptionLinkDisplayName: string;
    deliveryGpsPos: GpsLocation;
    department: string;
    email: string;
    history: EventViewModel[];
    ourRef: string;
    phoneNumber: string;
    photos: DeliveryPhoto[];
    route: string;
}
export interface PickupInfo {
    contactPerson: string;
    deliveryDescriptionLink: string;
    deliveryDescriptionLinkDisplayName: string;
    history: EventViewModel[];
    photos: DeliveryPhoto[];
    pickupAtPostalCodeAndCity: string;
    pickupAtStreetAndNumber: string;
    pickupBy: string;
    pickupFromName: string;
    pickupGpsPos: GpsLocation;
    pickupTimeUtc: Date | undefined;
}
export interface StoreErrorView extends StoreView {
    packagesWithErrors: StoreErrorSummary[];
    returnPackagesWithErrors: StoreReturnErrorSummary[];
}
export interface StoreHeaderInfo extends BaseHeaderInfo {
    header1: string;
    header2: string;
    header3: string;
}
export interface StoreOrderView extends StoreView {
    orders: OrderSummary[];
}
export interface StorePageView extends BasePageView<StoreView> {
}
export interface StoreReturnDeliveryPackagePageView extends BasePageView<StoreReturnDeliveryPackageView> {
}
export interface StoreReturnDeliveryPackageView {
    pickupInfo: PickupInfo;
}
export interface StoreView {
    accountNumber: string;
    line1: string;
    line2: string;
    name: string;
}
export interface GlobalStatistics {
    dayDeliveries: number;
    nightDeliveries: number;
    returnPickups: number;
    storeDeliveries: number;
    totalDropsToday: number;
    totalDropsTodayCompleted: number;
}
export interface SalesAccountStatistics {
    onTimePercentage: number;
    onTimePercentageLastMonth: number;
    totalFulfilledOrders: number;
    totalFulfilledOrdersLastMonth: number;
    totalOrders: number;
    totalOrdersLastMonth: number;
}
export enum DelayedDeliveryNotificationStep {
    NothingDelayed = 0,
    DeliveryDelayed = 1,
    DeliveryStillDelayed = 2,
    DeliveryDelayedStatusUnknown = 3
}
export enum StoreReturnStatus {
    Unhandled = 0,
    PickedUp = 1,
    ReturnedToTerminal = 2,
    ReturnedToTerminalManuallyScanned = 3,
    LostDuringReturn = 4,
    SendToReturnDepartment = 5,
    ArrivedAtReturnDepartment = 6
}
export interface DelayedDeliveryNotificationPeriod {
    deliveryMethod: string;
    fromTime: string;
    toTime: string;
}
export interface DelayedDeliveryNotificationState {
    deliveryNoteNumber: string;
    id: number;
    lastKnownEstimatedDeliveryTime: string;
    lastSentNotificationStep: DelayedDeliveryNotificationStep;
}
export interface DeliveryDescription {
    id: number;
    integrationId: string;
}
export interface DeliveryPhoto {
    exif: ExifData;
    note: string;
    url: string;
}
export interface DeliveryTimeCalculator {
}
export interface DeliveryTimeNotificationCommand {
    delayedDeliveryNotificationState: DelayedDeliveryNotificationState;
    deliveryMethod: string;
    estimatedDeliveryTime: string;
    timelyDeliveryToTime: string;
}
export interface DeliveryTimeNotificationEvaluator {
}
export interface ExifData {
    address: string;
    location: GpsLocation;
    takenUtc: Date;
}
export interface GpsLocation {
    id: number;
    latitude: number;
    longitude: number;
}
export interface Image {
    address: string;
    deliveryRoute: DeliveryRoute;
    exifCoordinateTimestamp: Date;
    gpsCoordinateTimestamp: Date;
    gpsLatitude: number;
    gpsLongitude: number;
    id: number;
    latitude: number;
    longitude: number;
    note: string;
    time: Date;
    url: string;
}
export interface ProjectPageView extends BasePageView<SalesAccountView> {
}
export interface CustomerPageView extends BasePageView<SalesAccountView> {
}
export interface ReturnDeliveryPackagePageView extends BasePageView<ReturnDeliveryPackageView> {
}
export interface ReturnDeliveryPackageView {
    pickupInfo: PickupInfo;
}
export interface TrackAndTraceView {
    deliveredTime: Date | undefined;
    deliveryNoteNumber: string;
    latitude: number;
    longitude: number;
    postalCode: string;
    statusDescription: string;
}
export interface CreatePlannedDeliveryRouteRequest {
    driverUsername: string;
    name: string;
    orderedDeliveries: PlannedDeliveryStopData[][];
    secretKey: string;
}
export interface CreatePlannedDeliveryRouteResponse {
    id: number;
}
export interface PlannedDeliveryRouteStopView {
    plannedDeliveries: DeliveryAndReturnView[];
    sortOrder: number;
}
export interface PlannedDeliveryRouteView {
    created: Date;
    id: number;
    name: string;
    stops: PlannedDeliveryRouteStopView[];
    stopsCount: number;
}
export interface PlannedDeliveryStopData {
    deliveryNoteNumber: string;
    type: DeliveryType;
}
export interface CompleteStoreReturnRequest {
    images: ImageData[];
    ssccNumbers: string[];
}
export interface CustomerReturnDeliveryPageView extends BasePageView<ReturnDeliveryView> {
}
export interface DeliveryAndReturnView {
    address: AddressSummary;
    customer: SalesAccountSummary;
    defectiveSsccNumbers: string[];
    deliveryMethodName: string;
    deliveryPriority: number;
    distributionCenter: DistributionCenterSummary;
    id: number;
    integrationLatitude: number;
    integrationLongitude: number;
    isDelivered: boolean;
    isReturn: boolean;
    latitude: number;
    longitude: number;
    noteNumber: string;
    packages: PackageView[];
    receivingStoreCode: string;
    routeNumber: string;
}
export interface DeliveryDescriptionView {
    deliveryDescriptionIds: { [key: number]: string };
}
export interface DeliveryPageView extends BasePageView<DeliveryView> {
}
export interface DeliveryView {
    address: AddressSummary;
    currentDeliveryRouteId: number | undefined;
    customer: SalesAccountSummary;
    deliveryDate: Date;
    deliveryInfo: DeliveryInfo;
    deliveryMethodName: string;
    deliveryTimeUtc: Date | undefined;
    id: number;
    isInTransit: boolean;
    latitude: number;
    longitude: number;
    noteNumber: string;
    order: OrderSummary;
    packages: PackageSummary[];
    routeNumber: string;
    salesAccount: SalesAccountSummary;
    status: DeliveryStatus;
}
export interface PackageView {
    packageType: string;
    ssccNumber: string;
}
export interface ReturnDeliveryView {
    address: AddressSummary;
    customer: SalesAccountSummary;
    id: number;
    latitude: number;
    longitude: number;
    noteNumber: string;
    packages: ReturnPackageSummary[];
    pickupInfo: PickupInfo;
    pickupTimeUtc: Date | undefined;
    routeNumber: string;
    salesAccount: SalesAccountSummary;
    status: DeliveryStatus;
}
export interface ShareDeliveryInfoRequest {
    email: string;
    images: string[];
    message: string;
    name: string;
}
export interface StoreReturnView {
    address: AddressSummary;
    hasBeenCompleted: boolean;
    id: number;
    ssccNumber: string;
    storeNumber: string;
    targetTerminalName: string;
}
export interface PackagePageView extends BasePageView<PackageView> {
}
export interface PackageView {
    customer: SalesAccountSummary;
    delivery: DeliverySummary;
    deliveryInfo: DeliveryInfo;
    id: number;
    lineItems: DeliveryLineItemSummary[];
    order: OrderSummary;
    ssccNumber: string;
}
export interface SharePackageInfoRequest {
    email: string;
    images: string[];
    message: string;
    name: string;
}
export interface UpdatePackageStatusRequest {
    images: ImageData[];
    latitude: number;
    longitude: number;
    ssccNumbers: string[];
    status: PackageStatus;
    terminal: string;
}
export interface CompletedDeliveryData {
    deliveredLatitude: number;
    deliveredLongitude: number;
    deliveredTime: Date;
    id: number;
    imageId: number;
    imageIds: number[];
    isReturnOrder: boolean;
}
export interface CompleteDeliveryRequest {
    completedDeliveries: CompletedDeliveryData[];
    deliveredPackages: PackageData[];
    images: ImageData[];
    packaging: PackagingRequest;
    splittedReturnIds: number[];
}
export interface CompleteRouteRequest {
    packaging: PackagingRequest;
    returnedPackages: PackageData[];
    storeReturns: StoreReturnData[];
}
export interface CreateDeliveryRouteRequest {
    appRouteId: string;
    orderedDeliveries: DeliveryStopData[][];
    packaging: PackagingRequest;
    plannedDeliveryRouteId: number | undefined;
    scannedPackages: PackageData[];
    scannedReturnPackages: PackageData[];
}
export interface DeliveryRouteCreatedView {
    deliveryRouteId: number;
}
export interface DeliveryRouteDetailsPageView extends BasePageView<DeliveryRouteDetailsView> {
}
export interface DeliveryRouteDetailsView {
    contactHeaderInfo: DriverContactHeaderInfo;
    currentDeliveryRouteId: number | undefined;
    deliveriesOnRoute: DeliveryOnRouteSummary[];
}
export interface DeliveryRouteView {
    currentTime: Date;
    deliveryStops: DeliveryStopView[];
    driver: DriverContactHeaderInfo;
    isCompleted: boolean;
    locationHistory: DriverGpsLocation[];
}
export interface DeliveryStopData {
    deliveryId: number;
    latitude: number;
    longitude: number;
    type: DeliveryType;
}
export interface DeliveryStopView {
    address: AddressSummary;
    customerName: string;
    deliveredTime: Date | undefined;
    deliveryMethodNames: string[];
    latitude: number;
    longitude: number;
    orderNumbers: string[];
    returnNumbers: string[];
    status: DeliveryStatus;
}
export interface ICompletedDeliveryData {
    deliveredLatitude: number;
    deliveredLongitude: number;
    deliveredTime: Date;
}
export interface ImageCoordinateInfo {
    coordinateTimestamp: Date;
    latitude: number;
    longitude: number;
}
export interface ImageData {
    address: string;
    exifCoordinateInfo: ImageCoordinateInfo;
    gpsCoordinateInfo: ImageCoordinateInfo;
    id: number;
    note: string;
    time: Date;
    url: string;
}
export interface PackageData {
    isReturn: boolean;
    ssccLabel: string;
    ssccNumbers: string[];
    status: string;
}
export interface PackagingRequest {
    blueBoxes: number;
    craneTime: number;
    euroPalletFrames: number;
    euroPallets: number;
}
export interface StoreReturnData {
    id: number;
    status: StoreReturnStatus;
}
export interface UpdateLocationRequest {
    latitude: number;
    longitude: number;
    timeStamp: Date;
}
export interface ExtendedDeliveryLineItemSummary extends DeliveryLineItemSummary {
    deliveryNoteNumber: string;
}
export interface OrderDeliveryLineItemView extends OrderView {
    lineItems: ExtendedDeliveryLineItemSummary[];
}
export interface OrderDeliveryView extends OrderView {
    deliveries: DeliverySummary[];
}
export interface OrderPageView extends BasePageView<OrderView> {
}
export interface OrderView {
    currentDeliveryRouteId: number | undefined;
    customer: SalesAccountSummary;
    isInTransit: boolean;
    orderNumber: string;
}
export interface AddOrUpdateNotificationRequest {
    addressId: number | undefined;
    email: string;
    endContactTime: string;
    phoneNumber: string;
    shouldReceiveEmailAfterDelivery: boolean;
    shouldReceiveEmailBeforeDelivery: boolean;
    shouldReceiveEmailWhenDelayed: boolean;
    shouldReceiveSmsAfterDelivery: boolean;
    shouldReceiveSmsBeforeDelivery: boolean;
    shouldReceiveSmsWhenDelayed: boolean;
    startContactTime: string;
}
export interface OptOutRequest {
    phoneNumber: string;
    secretKey: string;
}
export interface HasBasicRoleOrImageSecretKey {
}
export interface ExcludedPostalCodePutRequest {
    excludedPostalCode: string;
}
export interface ExcludedPostalCodesDeleteRequest {
    excludedPostalCodes: string[];
}
export interface CreateEventRequest {
    action: string;
    appRouteId: string | undefined;
    deliveryNumber: string;
    identifier: string;
    isReturnDelivery: boolean;
    isStoreReturnDelivery: boolean;
    location: EventLocation;
    newPackageStatus: PackageStatus | undefined;
    packageNumber: string;
    terminal: string;
    time: Date;
}
export interface CreateEventRequests {
    eventRequests: CreateEventRequest[];
}
export interface EventLocation {
    latitude: number;
    longitude: number;
}
export interface DriverContactHeaderInfo extends BaseHeaderInfo {
    header1: string;
    header2: string;
    header3: string;
}
export interface DriverDeliveryRoutesPageView extends BasePageView<DriverDeliveryRoutesView> {
}
export interface DriverDeliveryRoutesView {
    deliveryRoutes: DeliveryRouteSummary[];
}
export interface DriverPageView extends BasePageView<DriverView> {
}
export interface DriverView {
    contactHeaderInfo: DriverContactHeaderInfo;
    currentDeliveryRouteId: number | undefined;
    deliveriesOnRoute: DeliveryOnRouteSummary[];
}
export interface DistributionCenterHeaderInfo extends BaseHeaderInfo {
    header1: string;
    header2: string;
    header3: string;
}
export interface DistributionCenterMessageRequest {
    message: string;
}
export interface DistributionCenterPageView extends BasePageView<DistributionCenterView> {
}
export interface DistributionCenterRequest {
    isDelayedNotificationEnabled: boolean;
}
export interface DistributionCenterView {
    distributionCenter: DistributionCenterSummary;
    packagesWithErrors: DistributionCenterErrorSummary[];
    returnPackagesWithErrors: DistributionCenterReturnErrorSummary[];
}
export interface CompressedShipment {
    deliveryGroupId: number | undefined;
    deliveryId: number | undefined;
    id: number;
    returnDeliveryId: number | undefined;
    sortOrder: number;
}
export interface DeliveryId {
    id: number;
    type: DeliveryType;
}
export interface DeliveryOnRoute {
    address: Address;
    city: string;
    country: string;
    customerName: string;
    deliveredTime: Date | undefined;
    isReturnDelivery: boolean;
    line1: string;
    line2: string;
    line3: string;
    noteNumber: string;
    orderNumber: string;
    postalCode: string;
    routeNumber: string;
    status: DeliveryStatus;
}
export interface DeliveryRoute {
    appRouteId: string;
    created: Date;
    driver: Driver;
    id: number;
    isCompleted: boolean;
    loadedPackaging: Packaging;
    plannedDeliveryRoute: PlannedDeliveryRoute;
    returnedPackaging: Packaging;
    shipments: Shipment[];
}
export interface DeliveryStop {
    address: Address;
    deliveredTime: Date | undefined;
    deliveries: Delivery[];
    isFirstStop: boolean;
    latitude: number;
    longitude: number;
    name: string;
    returnDeliveries: CustomerReturnDelivery[];
    sortOrder: number;
    status: DeliveryStatus;
}
export interface DeliveryStopCalculator {
}
export interface DriverGpsLocation {
    deliveryRouteId: number;
    id: number;
    latitude: number;
    longitude: number;
    timeStamp: Date;
}
export interface DeliveryDescriptionView {
    accountNumber: string;
    address: string;
    address1: string;
    address2: string;
    addressNumber: string;
    alarm: string;
    alarmCode: string;
    alarmDescription: string;
    city: string;
    company: string;
    contactPerson: string;
    contactPersonTelephoneNumber: string;
    created: Date;
    customerName: string;
    deliveryLabel: string;
    deliveryType: string;
    department: string;
    departmentTelephoneNumber: string;
    formData: string;
    gpsLatitude: string;
    gpsLongitude: string;
    id: string;
    keybox: string;
    keyboxInfo: string;
    modified: string;
    newAddress: string;
    newAddressNumber: string;
    otherDocuments: FileAttachment[];
    pictures: PictureFileAttachment[];
    postalCode: string;
    receipt: string;
    remarks: string;
    returnLabel: string;
    routeNumber: string;
    routeNumberText: string;
    salesman: string;
    salesmanCellNumber: string;
    salesmanEmail: string;
    salesmanId: string;
    status: string;
    terminalId: string;
    title: string;
}
export interface DeliveryDescriptionInfo {
    accountNumber: string;
    address: string;
    address1: string;
    address2: string;
    addressNumber: string;
    alarm: string;
    alarmCode: string;
    alarmDescription: string;
    city: string;
    company: string;
    contactPerson: string;
    contactPersonTelephoneNumber: string;
    created: Date;
    customerName: string;
    deliveryLabel: string;
    deliveryType: string;
    department: string;
    departmentTelephoneNumber: string;
    formData: string;
    gpsLatitude: string;
    gpsLongitude: string;
    id: string;
    keybox: string;
    keyboxInfo: string;
    modified: string;
    newAddress: string;
    newAddressNumber: string;
    otherDocuments: FileAttachment[];
    pictures: PictureFileAttachment[];
    postalCode: string;
    receipt: string;
    remarks: string;
    returnLabel: string;
    routeNumber: string;
    routeNumberText: string;
    salesman: string;
    salesmanCellNumber: string;
    salesmanEmail: string;
    salesmanId: string;
    status: string;
    terminalId: string;
    title: string;
}
export interface DeliveryDescriptionInfoMapper {
}
export interface FileAttachment {
    fileName: string;
    url: string;
}
export interface PictureFileAttachment {
    description: string;
    fileName: string;
    url: string;
}
export interface KeyValuePair<TKey, TValue> {
    key: TKey;
    value: TValue;
}
export interface ConsentRequest {
    consentPolicyId: number;
}
export interface GlobalStatusCreateRequest {
    message: string;
}
export interface NavigationLinkCreateRequest {
    displayName: string;
    icon: string;
    url: string;
}
export interface NavigationView {
    links: NavigationLink[];
}
export interface Declaration {
    created: Date;
    id: number;
    text: string;
}
export interface GlobalStatus {
    created: Date;
    message: string;
}
export interface NavigationLink {
    displayName: string;
    icon: string;
    id: number;
    url: string;
}
export interface TermsAndConditions {
    consentPolicy: Declaration;
}
export interface ComplaintPackagesView {
    complaintPackages: ComplaintPackageView[];
}
export interface ComplaintPackageView {
    deliveryInfoUrl: string;
    deliveryTime: Date | undefined;
    lineNumber: string;
    noteNumber: string;
    productNumber: string;
    route: string;
    ssccNumber: string;
    status: string;
}
export interface ComplaintReturnPackagesView {
    complaintPackages: ComplaintReturnPackageView[];
}
export interface ComplaintReturnPackageView {
    deliveryInfoUrl: string;
    deliveryTime: Date | undefined;
    noteNumber: string;
    route: string;
    ssccNumber: string;
    status: string;
}
export interface AppLoginView extends LoginView {
    consentPolicyId: number;
    distributionCenterId: number;
    mustChangePassword: boolean;
}
export interface ChangePasswordRequest {
    newPassword: string;
    oldPassword: string;
}
export interface LoginRequest {
    password: string;
    username: string;
}
export interface LoginView {
    name: string;
    permissions: string[];
    token: string;
    username: string;
}
export interface ResetPasswordRequest {
    username: string;
}
export enum AuthenticationAttemptSource {
    Portal = 0,
    App = 1
}
export enum DeliveryType {
    Delivery = 0,
    ReturnDelivery = 1
}
export enum OrderType {
    CustomerOrder = 0,
    DistributionOrder = 1
}
export interface Address {
    city: string;
    country: string;
    deliveryDescription: DeliveryDescription;
    id: number;
    integrationId: string;
    integrationKey: string;
    line1: string;
    line2: string;
    line3: string;
    name: string;
    postalCode: string;
    salesAccount: SalesAccount;
}
export interface AuthenticationAttempt {
    created: Date;
    id: number;
    ipAddress: string;
    isSuccess: boolean;
    source: AuthenticationAttemptSource;
    username: string;
}
export interface CustomerReturnDelivery {
    address: Address;
    customer: Customer;
    deliveredLatitude: number | undefined;
    deliveredLongitude: number | undefined;
    deliveredTime: Date | undefined;
    deliveryPostponedToEndOfRoute: boolean;
    distributionRoute: DistributionRoute;
    id: number;
    images: Image[];
    noteNumber: string;
    packages: ReturnPackage[];
    salesAccount: SalesAccount;
    shipments: Shipment[];
    status: DeliveryStatus;
    statusSortOrder: number;
}
export interface DelayedDeliveryExcludedPostalCode {
    id: number;
    postalCode: string;
}
export interface Delivery {
    address: Address;
    deliveredLatitude: number | undefined;
    deliveredLongitude: number | undefined;
    deliveredTime: Date | undefined;
    deliveryDate: Date;
    deliveryMethod: string;
    deliveryMethodName: string;
    deliveryMethodPriority: number;
    deliveryMethodPriorityName: string;
    distributionRoute: DistributionRoute;
    email: string;
    events: DeliveryEvent[];
    id: number;
    images: Image[];
    integrationPackagingDate: Date;
    isDeletedByIntegration: boolean;
    isDelivered: boolean;
    isScannable: boolean;
    isSearchable: boolean;
    noteNumber: string;
    order: Order;
    orderId: number;
    packages: Package[];
    phoneNumber: string;
    receivingWarehouse: string;
    shipments: Shipment[];
    status: DeliveryStatus;
    statusSortOrder: number;
    timelyDeliveryFromTime: string;
    timelyDeliveryToTime: string;
    warehouse: string;
}
export interface DeliveryGroup {
    id: number;
    returnShipments: Shipment[];
    shipments: Shipment[];
}
export interface DeliveryLineItem {
    deliveredQuantity: number;
    id: number;
    integrationId: string;
    isDeletedByIntegration: boolean;
    itemDescription: string;
    itemName: string;
    itemNumber: string;
    lineNumber: string;
    lineNumberSuffix: string;
    orderedLineNumber: number;
    orderedQuantity: number | undefined;
    package: Package;
    status: PackageStatus;
    statusSortOrder: number;
    statusTerminal: string;
    unitOfMeasurement: string;
    warehouseArea: string;
}
export interface DistributionCenter {
    city: string;
    country: string;
    id: number;
    isDelayedNotificationEnabled: boolean;
    latitude: number;
    longitude: number;
    message: string;
    messageLastUpdated: Date | undefined;
    name: string;
    phoneNumber: string;
    postalCode: string;
    streetName: string;
}
export interface DistributionRoute {
    description: string;
    distributionCenter: DistributionCenter;
    id: number;
    isStoreCustomerPickupRoute: boolean;
    isStoreDeliveryRoute: boolean;
    name: string;
    receivingStoreCode: string;
    routeNumber: string;
}
export interface Driver {
    canUseTerminal: boolean;
    deliveryRoutes: DeliveryRoute[];
    distributionCenter: DistributionCenter;
    email: string;
    events: UserEvent[];
    hasTemporaryPassword: boolean;
    id: number;
    isActive: boolean;
    mobileNumber: string;
    name: string;
    passwordHash: string;
    userData: UserData[];
    username: string;
}
export interface IDelivery {
    deliveredLatitude: number | undefined;
    deliveredLongitude: number | undefined;
    deliveredTime: Date | undefined;
    id: number;
    images: Image[];
}
export interface Order {
    belongingDepartment: string;
    customer: Customer;
    deliveries: Delivery[];
    id: number;
    isDeletedByIntegration: boolean;
    isInTransit: boolean;
    isSearchable: boolean;
    orderNumber: string;
    orderType: OrderType;
    ourReference: string;
    salesAccount: SalesAccount;
    status: OrderStatus;
    statusSortOrder: number;
}
export interface Package {
    delivery: Delivery;
    deliveryId: number;
    events: PackageEvent[];
    id: number;
    images: Image[];
    isDeletedByIntegration: boolean;
    isScannable: boolean;
    isSearchable: boolean;
    lastModified: Date;
    lineItems: DeliveryLineItem[];
    packageGroup: PackageGroup;
    packageGroupId: number | undefined;
    packageType: string;
    ssccNumber: string;
    status: PackageStatus;
    statusSortOrder: number;
    statusTerminal: string;
    volume: string;
    weight: string;
}
export interface PackageGroup {
    id: number;
    packages: Package[];
    ssccNumber: string;
}
export interface ReturnPackage {
    customerReturnDelivery: CustomerReturnDelivery;
    customerReturnDeliveryId: number;
    events: PackageEvent[];
    id: number;
    isScannable: boolean;
    ssccNumber: string;
    status: PackageStatus;
    statusSortOrder: number;
    statusTerminal: string;
}
export interface Shipment {
    customerReturnDelivery: CustomerReturnDelivery;
    deliveredTime: Date | undefined;
    delivery: Delivery;
    deliveryGroup: DeliveryGroup;
    deliveryGroupId: number | undefined;
    deliveryRoute: DeliveryRoute;
    id: number;
    packageId: number | undefined;
    packaging: Packaging;
    returnPackageId: number | undefined;
    sortOrder: number;
}
export interface StoreReturnDelivery {
    completedTime: Date | undefined;
    createdDate: Date;
    events: PackageEvent[];
    fromAddress: Address;
    fromLatitude: number;
    fromLongitude: number;
    fromWarehouse: string;
    id: number;
    images: Image[];
    shipments: StoreReturnShipment[];
    ssccNumber: string;
    status: StoreReturnStatus;
    toAddress: Address;
}
export interface StoreReturnShipment {
    created: Date;
    deliveredTime: Date | undefined;
    driver: Driver;
    id: number;
    storeReturnDelivery: StoreReturnDelivery;
}
export interface UserData {
    id: number;
    lastAcceptedConsentPolicyId: number;
    username: string;
}
export enum CustomerType {
    Customer = 0,
    Store = 1
}
export interface Customer extends SalesAccount {
    allOrders: Order[];
    allReturnDeliveries: CustomerReturnDelivery[];
    customerType: CustomerType;
}
export interface Project extends SalesAccount {
    customer: Customer;
}
export interface SalesAccount {
    accountNumber: string;
    addresses: Address[];
    addressLine1: string;
    addressLine2: string;
    city: string;
    id: number;
    isSearchable: boolean;
    name: string;
    orders: Order[];
    phoneNumber1: string;
    phoneNumber2: string;
    postalCode: string;
    returnDeliveries: CustomerReturnDelivery[];
    status: number;
    storeCode: string;
}
export interface DeliveryEvent {
    appRouteId: string | undefined;
    deliveryRoute: DeliveryRoute;
    description: string;
    id: number;
    identifier: string;
    latitude: number;
    longitude: number;
    noteNumber: string;
    timestamp: Date;
    type: DeliveryType;
    username: string;
}
export interface IEvent {
    description: string;
    id: number;
    identifier: string;
    latitude: number;
    longitude: number;
    timestamp: Date;
    type: DeliveryType;
    username: string;
}
export interface PackageEvent {
    appRouteId: string | undefined;
    deliveryRoute: DeliveryRoute;
    description: string;
    id: number;
    identifier: string;
    latitude: number;
    longitude: number;
    package: Package;
    returnPackage: ReturnPackage;
    ssccNumber: string;
    status: PackageStatus | undefined;
    storeReturnDelivery: StoreReturnDelivery;
    timestamp: Date;
    type: DeliveryType;
    username: string;
}
export interface UserEvent {
    action: string;
    appRouteId: string | undefined;
    deliveryRoute: DeliveryRoute;
    id: number;
    identifier: string;
    latitude: number;
    longitude: number;
    timestamp: Date;
    username: string;
}
export enum PackagingReportType {
    Pallet = 0,
    PalletFrame = 1,
    CraneTime = 2,
    BlueBox = 3
}
export interface Packaging {
    blueBoxes: number;
    craneTime: number;
    id: number;
    palletFrames: number;
    pallets: number;
}
export interface PackagingReportQueueItem {
    deliveryNoteNumber: string;
    deliveryRouteId: number;
    id: number;
    integrationRunId: string;
    orderNumber: string;
    orderType: OrderType;
    packagingType: PackagingReportType;
    quantity: number;
    receivingWarehouse: string;
    username: string;
}
export interface PlannedDelivery {
    deliveryNoteNumber: string;
    id: number;
    stop: PlannedDeliveryRouteStop;
    type: DeliveryType;
}
export interface PlannedDeliveryRoute {
    created: Date;
    driver: Driver;
    id: number;
    isCompleted: boolean;
    name: string;
    stops: PlannedDeliveryRouteStop[];
}
export interface PlannedDeliveryRouteStop {
    id: number;
    plannedDeliveries: PlannedDelivery[];
    route: PlannedDeliveryRoute;
    sortOrder: number;
}
export enum DeliveryStatus {
    NotScanned = 0,
    Scanned = 1,
    Delivered = 2,
    InTransit = 3,
    PartiallyDelivered = 4,
    CouldNotBeDelivered = 6,
    AwaitingReturn = 7,
    NotReturned = 8,
    Returned = 9
}
export enum OrderStatus {
    NotScanned = 0,
    NotDelivered = 1,
    InTransit = 2,
    PartiallyDelivered = 3,
    Delivered = 4
}
export enum PackageStatus {
    NotScanned = 0,
    Scanned = 1,
    NotFound = 2,
    Unscannable = 3,
    DefectiveByDriver = 4,
    Delivered = 5,
    Returned = 6,
    ReturnedManuallyScanned = 7,
    ReturnPoorlyPacked = 8,
    ReturnNotPacked = 9,
    ReturnLabelNotUsed = 10,
    ReturnedToTerminal = 11,
    ReturnedToTerminalManuallyScanned = 12,
    WrongTerminal = 13,
    WrongRoute = 14,
    SortingError = 15,
    LocationRegistration = 16,
    IsInWarehouse = 17,
    CarWithoutScanner = 18,
    LargeVolume = 19,
    SendToReturnDepartment = 20,
    DeliveredManuallyScanned = 21,
    CanNotBeDelivered = 22,
    LostDuringDelivery = 23,
    LostDuringReturn = 24,
    DeliveredWithReservations = 25,
    ArrivedAtReturnDepartment = 26,
    DefectiveByTerminal = 27
}
export interface StatusWithPriority<T> {
    priority: number;
    status: T;
}
export enum NotificationType {
    BeforeDelivery = 0,
    AfterDelivery = 1,
    DelayedDelivery = 2
}
export interface Notification {
    deliveryId: number;
    email: string;
    id: number;
    key: string;
    phoneNumber: string;
    type: NotificationType;
}
export interface NotificationSetting {
    addressId: number | undefined;
    email: string;
    endContactTime: string;
    id: number;
    phoneNumber: string;
    salesAccountId: number | undefined;
    shouldReceiveEmailAfterDelivery: boolean;
    shouldReceiveEmailBeforeDelivery: boolean;
    shouldReceiveEmailWhenDelayed: boolean;
    shouldReceiveSmsAfterDelivery: boolean;
    shouldReceiveSmsBeforeDelivery: boolean;
    shouldReceiveSmsWhenDelayed: boolean;
    startContactTime: string;
}
export interface FailedRequest {
    authHeader: string;
    body: string;
    exception: string;
    files: string;
    id: number;
    rerunAttempts: number;
    url: string;
    username: string;
}
export interface DeliveryIsDelayedFurtherHandler extends ChainOfResponsibilityLink<DeliveryTimeNotificationCommand, DelayedDeliveryNotificationStep> {
}
export interface DeliveryIsDelayedHandler extends ChainOfResponsibilityLink<DeliveryTimeNotificationCommand, DelayedDeliveryNotificationStep> {
}
export interface DeliveryIsEarlierThanPreviouslyExpectedHandler extends ChainOfResponsibilityLink<DeliveryTimeNotificationCommand, DelayedDeliveryNotificationStep> {
}
export interface DeliveryIsNotInNotificationPeriodHandler extends ChainOfResponsibilityLink<DeliveryTimeNotificationCommand, DelayedDeliveryNotificationStep> {
}
export interface DeliveryNotificationsAllSentHandler extends ChainOfResponsibilityLink<DeliveryTimeNotificationCommand, DelayedDeliveryNotificationStep> {
}
export interface ChainOfResponsibilityLink<T, TResult> {
    next: ChainOfResponsibilityLink<T, TResult>;
}
export enum LogisticsType {
    Order = 0,
    Delivery = 1,
    Package = 2,
    CustomerReturnDelivery = 3,
    ReturnPackage = 4,
    Store = 5,
    Driver = 6,
    DistributionCenter = 7,
    Customer = 8,
    Project = 9,
    DeliveryRoute = 10
}
export interface Facet {
    count: number;
    type: LogisticsType;
}
export interface SearchQueryResult {
    facets: Facet[];
    hasMoreResults: boolean;
    results: SearchResult[];
}
export interface SearchResult {
    id: string;
    keywords: string[];
    logisticsId: string;
    name: string;
    subtitle: string;
    type: LogisticsType;
}
export enum CustomerReturnDeliverySortOrder {
    NoteNumber = 0,
    Route = 1,
    Address = 2,
    PostalCode = 3,
    City = 4,
    DeliveredTime = 5,
    Status = 6
}
export enum DeliveryLineItemSortOrder {
    ItemNumber = 0,
    LineNumber = 1,
    Name = 2,
    UnitOfMeasure = 3,
    OrderedQuantity = 4,
    DeliveredQuantity = 5,
    Status = 6
}
export enum DeliveryOnRouteSortOrder {
    RouteNumber = 0,
    CustomerName = 1,
    Address = 2,
    PostalCode = 3,
    City = 4,
    OrderNumber = 5,
    NoteNumber = 6,
    DeliveredTime = 7,
    Status = 8
}
export enum DeliveryRouteSortOrder {
    CreatedTime = 0
}
export enum DeliverySortOrder {
    NoteNumber = 0,
    Route = 1,
    Address = 2,
    PostalCode = 3,
    City = 4,
    DeliveryDate = 5,
    Status = 6
}
export enum ErrorSortOrder {
    SsccNumber = 0,
    Owner = 1,
    WarehouseArea = 2,
    OrderNumber = 3,
    CustomerName = 4,
    OurReference = 5,
    DeliveryDate = 6,
    Status = 7,
    RouteNumber = 8,
    Warehouse = 9
}
export enum ExtendedDeliveryLineItemSortOrder {
    DeliveryNoteNumber = 0,
    ItemNumber = 1,
    LineNumber = 2,
    Name = 3,
    UnitOfMeasure = 4,
    OrderedQuantity = 5,
    DeliveredQuantity = 6,
    Status = 7
}
export enum OrderSortOrder {
    OrderNumber = 0,
    SalesAccountNumber = 1,
    Route = 2,
    Address = 3,
    PostalCode = 4,
    City = 5,
    DeliveryDate = 6,
    Status = 7
}
export enum PackageSortOrder {
    SsccNumber = 0,
    PackageGroup = 1,
    Quantity = 2,
    DeliveryDate = 3,
    WarehouseArea = 4,
    Warehouse = 5,
    Status = 6,
    PackageType = 7
}
export enum ReturnPackageSortOrder {
    SsccNumber = 0,
    Status = 1,
    DeliveredDate = 2
}
export enum SortBy {
    Ascending = 0,
    Descending = 1
}
export enum StoreReturnDeliverySortOrder {
    SsccNumber = 0,
    NoteNumber = 1,
    DeliveryTime = 2,
    Status = 3
}
export interface Pagination<T> {
    skip: number;
    sortBy: SortBy;
    sortOrder: T;
    take: number;
}
export interface PaginationWithDate<T> extends Pagination<T> {
    fromDate: Date | undefined;
    toDate: Date | undefined;
    toDateForQuery: Date | undefined;
}

